// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_btn__x7t\\+f{\n    font-size: var(--font-size-rm1);\n}\n\n.Login_label__zlJQ-{\n    font-size: var(--font-size-rm1);\n}\n\n.Login_input__ADhfa{\n    font-size: var(--font-size-rm1);\n}\n\n.Login_wcblogo__wC5Zq {\n    width: 25%;\n    opacity: 35%;\n}\n\n.Login_wcblogo50__DzNan {\n    width: 65%;\n    opacity: 35%;\n}", "",{"version":3,"sources":["webpack://./src/components/Login.module.css"],"names":[],"mappings":"AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB","sourcesContent":["@import '../indexVars.css';\n\n.btn{\n    font-size: var(--font-size-rm1);\n}\n\n.label{\n    font-size: var(--font-size-rm1);\n}\n\n.input{\n    font-size: var(--font-size-rm1);\n}\n\n.wcblogo {\n    width: 25%;\n    opacity: 35%;\n}\n\n.wcblogo50 {\n    width: 65%;\n    opacity: 35%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "Login_btn__x7t+f",
	"label": "Login_label__zlJQ-",
	"input": "Login_input__ADhfa",
	"wcblogo": "Login_wcblogo__wC5Zq",
	"wcblogo50": "Login_wcblogo50__DzNan"
};
export default ___CSS_LOADER_EXPORT___;
