import { handleResponse } from '../helpers/responser';
import { configApiLinks } from '../configApiLinks';
import { fetcher } from '../helpers/fetcher';

export const SET_CHECK_DATA = 'SET_CHECK_DATA';
export const DISPLAY_MODAL = 'DISPLAY_MODAL';
export const CHECK_SUBMIT_PAYMENT = 'CHECK_SUBMIT_PAYMENT';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_CHECK_DONE_DATA = 'SET_CHECK_DONE_DATA';
export const HIDE_DONE_CHECK = 'HIDE_DONE_CHECK';
export const SHOW_LOADER_ON_CHECK = 'SHOW_LOADER_ON_CHECK';
export const SET_SYST_ERROR = 'SET_SYST_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const SET_RECEIPT_COPY = 'SET_RECEIPT_COPY';
export const SET_RECEIPT_COPY_HIDE = 'SET_RECEIPT_COPY_HIDE';
export const SET_PAYMENT_MODAL_STATE = 'SET_PAYMENT_MODAL_STATE';

export function getCheckData() {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER_ON_CHECK });
    fetcher(configApiLinks.getCheckData, 'GET')
      .then((data) => {
        console.log('daaaata 10 ', data);
        dispatch({ type: SET_CHECK_DATA, data });
      })
      .catch((data) => {
        dispatch({ type: SET_SYST_ERROR, data });
      });
  };
}

export function updateProductCount(product) {
  if (product.count == 0) {
    return removeProduct(product.productId);
  } else {
    return (dispatch, getState) => {
      dispatch({ type: SHOW_LOADER_ON_CHECK });
      fetcher(configApiLinks.updateProductCount, 'POST', {
        productId: product.productId,
        count: product.count,
      })
        .then((data) => {
          dispatch({ type: SET_CHECK_DATA, data });
        })
        .catch((data) => {
          dispatch({ type: SET_SYST_ERROR, data });
        });
    };
  }
}

export function submitCheckPayment(
  cash,
  nonCash,
  change,
  total,
  receiptId = null,
  fiscalizeReceipt = false
) {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER_ON_CHECK });
    fetcher(configApiLinks.makePayment, 'POST', {
      cash,
      nonCash,
      change,
      total,
      receiptId,
      fiscalizeReceipt,
    })
      .then((data) => {
        dispatch({ type: SET_CHECK_DATA, data });
      })
      .catch((data) => {
        dispatch({ type: SET_SYST_ERROR, data });
      });
  };
}

export function addProductToCheck(code) {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER_ON_CHECK });
    fetcher(configApiLinks.addProduct, 'POST', { scannedCode: code })
      .then((data) => {
        dispatch({ type: SET_CHECK_DATA, data });
      })
      .catch((data) => {
        dispatch({ type: SET_SYST_ERROR, data });
      });
  };
}

export function addProductToCheckByWeighCode(code) {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER_ON_CHECK });
    fetcher(configApiLinks.findAddProductWeightCode, 'POST', {
      weightCode: code,
    })
      .then((data) => {
        dispatch({ type: SET_CHECK_DATA, data });
      })
      .catch((data) => {
        dispatch({ type: SET_SYST_ERROR, data });
      });
  };
}

export function sendCheckToApprove() {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER_ON_CHECK });
    console.log('checkData', getState().actions.checkData);
    fetcher(configApiLinks.sendToApprove, 'POST', getState().actions.checkData)
      .then((data) => {
        dispatch({ type: SET_CHECK_DONE_DATA, data });
      })
      .catch((data) => {
        dispatch({ type: SET_SYST_ERROR, data });
      });
  };
}

export function removeProduct(id) {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER_ON_CHECK });
    fetcher(configApiLinks.removeProduct, 'DELETE', { productId: id })
      .then((data) => {
        dispatch({ type: SET_CHECK_DATA, data });
      })
      .catch((data) => {
        dispatch({ type: SET_SYST_ERROR, data });
      });
  };
}

export function setDiscountManual(discountPercentage, productId = null) {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER_ON_CHECK });
    fetcher(configApiLinks.setDiscountManual, 'POST', {
      discountPercentage,
      productId,
    })
      .then((data) => {
        dispatch({ type: SET_CHECK_DATA, data });
      })
      .catch((data) => {
        dispatch({ type: SET_SYST_ERROR, data });
      });
  };
}

export function returnReceipt(receiptId, products) {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER_ON_CHECK });
    fetcher(configApiLinks.returnReceipt, 'POST', { receiptId, products })
      .then((data) => {
        dispatch({ type: SET_CHECK_DATA, data });
      })
      .catch((data) => {
        dispatch({ type: SET_SYST_ERROR, data });
      });
  };
}

export function cancelReceiptReturn() {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER_ON_CHECK });
    fetcher(configApiLinks.cancelReceiptReturn, 'POST', {})
      .then((data) => {
        dispatch({ type: SET_CHECK_DATA, data });
      })
      .catch((data) => {
        dispatch({ type: SET_SYST_ERROR, data });
      });
  };
}

export function clearReceiptProducts() {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER_ON_CHECK });
    fetcher(configApiLinks.clearReceiptProducts, 'POST', {})
      .then((data) => {
        dispatch({ type: SET_CHECK_DATA, data });
      })
      .catch((data) => {
        dispatch({ type: SET_SYST_ERROR, data });
      });
  };
}

export function setReceiptPrintCopy(data) {
  console.log('setReceiptPrintCopy->receiptData', data);
  return (dispatch, getState) => {
    dispatch({ type: SET_RECEIPT_COPY, data });
  };
}

export function setReceiptPrintCopyHide() {
  return (dispatch, getState) => {
    const data = {};
    dispatch({ type: SET_RECEIPT_COPY_HIDE, data });
  };
}

export function setPaymentModalIsActive(isActive) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PAYMENT_MODAL_STATE, data: isActive });
  };
}