import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/users";
import { Button, Container, Form, Row, Alert } from "react-bootstrap";
import classes from './Login.module.css'
import WcbLogo from '../images/wcb_logo.svg';
import { useWindowSize } from './hooks/useWindowSize';

function Login() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const error = useSelector((state) => state.users.error);
  const { isNonMobile } = useWindowSize();
  const handleCredentials = (event) => {
    const prop = event.target.name;
    if (prop === "username") {
      setUsername(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  const submitLogin = (event) => {
    event.preventDefault();
    console.log("username", username, password);
    dispatch(login(username, password));
  };
  return (
    <Container>
      <Row
        xs={1}
        md={1}
        // className={`justify-content-center align-items-center vh-${isNonMobile ? 50 : 100}`}
        className="justify-content-center align-items-center vh-50"
        style={{ marginTop: '10vh', marginBottom: '3vh' }}
      >
        <img className={isNonMobile ? classes.wcblogo : classes.wcblogo50} src={WcbLogo} alt="logo" />
        <br />
      </Row>
      <Row
        xs={12}
        md={3}
        className="justify-content-center align-items-center vh-50"
      >
        <Form>
          {error !== "" && <Alert variant={"danger"}>{error}</Alert>}
          <Form.Group className="mb-3" controlId="formGroupLogin">
            <Form.Label>Логін</Form.Label>
            <Form.Control
              type="text"
              placeholder="логін"
              onChange={handleCredentials}
              value={username}
              name={"username"}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submitLogin(e);
                }
              }}
              className={classes.input}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupPassword">
            <Form.Label>Пароль</Form.Label>
            <Form.Control
              type="password"
              placeholder="Пароль"
              onChange={handleCredentials}
              value={password}
              name={"password"}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submitLogin(e);
                }
              }}
              className={classes.input}
            />
          </Form.Group>

          <div className="text-center">
            <Button className={`w-50 ${classes.btn}`} variant="success" onClick={submitLogin}>
              Вхід
            </Button>
          </div>
        </Form>
      </Row>
    </Container>

    // <div>
    //     <form>
    //         <input type={'text'}
    //                onChange={handleCredentials}
    //                value={username} name={"username"} />
    //         <input type={'text'}
    //                onChange={handleCredentials}
    //                value={password} name={"password"} />
    //         <button onClick={submitLogin}>{'Submit'}</button>
    //     </form>
    // </div>
  );
}

export default Login;
