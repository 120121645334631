import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  addProductToCheck,
  DISPLAY_MODAL,
  getCheckData,
  REMOVE_ERROR,
} from '../actions/checks';
import ModalCount from './modals/Modal-Count';
import CheckList from './Check.list';
//import ControlCheck from './ControlCheck';
//import ModalPayment from './modals/Modal-Payment';
import { useScanWaiting } from '../syst/Scanning';
// import ModalAddCode from './modals/Modal-AddCode';
// import HeaderButtonWrapper from './HeaderButtonWrapper';
// import ModalUserInfo from './modals/Modal-UserInfo';
// import ModalCheckDone from './modals/Modal-CheckDone';
import { Container, Toast, ToastContainer, Row, Col } from 'react-bootstrap';

import WeightCodeKeyboard from './Header/weightcode_keyboard';
import Header from './Header/header';
import Footer from './Footer/footer';
import CheckProductlist from './CheckList/checklist';
import classes from './check.module.css';

import Loading from './Loading';

import ReceiptPrint from './modals/modal_receipt_print';
import config from '../config';
import { useWindowSize } from './hooks/useWindowSize';

export default function Check() {
  const { isNonMobile } = useWindowSize();
  const dispatch = useDispatch();
  const checkData = useSelector(
    (state) => state.actions.checkData,
    shallowEqual
  );
  const isShowLoader = useSelector(
    (state) => state.actions.isShowLoader,
    shallowEqual
  );
  const user = useSelector((state) => state.users.user.token);
  const hideAnyModal = useSelector((state) => state.actions.hideAnyModal);
  const sysAPIerror = useSelector((state) => state.actions.sysAPIerror);
  const paymentModalIsActive = useSelector((state) => state.actions.paymentModalIsActive);

  // useEffect(()=>{
  //   console.log('paymentModalIsActive', paymentModalIsActive);
  // }, [paymentModalIsActive]);

  useScanWaiting(13, hideAnyModal && !paymentModalIsActive, [], { dispatch, mthd: addProductToCheck });
  console.log('user', user);
  useEffect(() => {
    dispatch(getCheckData());
  }, [dispatch, user]);

  return (
    // <Container className="p-0">
    <Container fluid>
      {/* {isShowLoader && <Loading />} */}
      {sysAPIerror !== '' && (
        <ToastContainer className='p-3' position={'middle-center'}>
          <Toast
            onClose={() => {
              dispatch({ type: REMOVE_ERROR });
            }}
            bg={'danger'}
            show={sysAPIerror !== ''}
            delay={3000}
            autohide={true}
          >
            <Toast.Header closeButton>
              <strong className='me-auto'>Помилка</strong>
            </Toast.Header>
            <Toast.Body>{sysAPIerror}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <Header />
      <Row>
        <Col xs={12} md={9} lg={9} xl={9} xxl={10} style={{ paddingLeft: isNonMobile ? '1vh' : '0', paddingRight: isNonMobile ? '1vh' : '0' }}>
          <CheckProductlist id={'maintReceiptsList'} isShowLoader={isShowLoader} checkData={checkData} />
        </Col>
        {isNonMobile &&
          (<Col xs={2} md={3} lg={3} xl={3} xxl={2} className={classes.weightKeyboard}>
            <WeightCodeKeyboard />
          </Col>)}
      </Row>
      <Footer checkData={checkData} />
      <ReceiptPrint />
    </Container>
  );
}
