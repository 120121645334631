import { fetcherAsync } from "./fetcherAsync";
import { configApiLinks } from "../configApiLinks";
import { config } from "../config";
import frPrintTypes from "../enumerates/frPrintTypes";

const printImage = (url) => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
        <html>
        <head>
            <title>PRINT</title>
            <style>
                body { text-align: center; margin: 0; padding: 20px; }
                img { max-width: 100%; height: auto; cursor: pointer; }
            </style>
        </head>
        <body>
            <img src="${url}" onclick="window.close()" ontouchstart="window.close()" onload="setTimeout(() => window.print(), 500);">
            <script>
                let hasPrinted = false;

                function handleVisibilityChange() {
                    if (document.visibilityState === 'hidden') {
                        hasPrinted = true;
                    } else if (hasPrinted) {
                        setTimeout(() => window.close(), 1000);
                    }
                }

                document.addEventListener('visibilitychange', handleVisibilityChange);
            </script>
        </body>
        </html>
    `);
    printWindow.document.close();
}

export const printShowXZReport = async (frData, isZReport) => {

    const printFilename = (isZReport ? "zreport_" : "xreport_") + frData.id;
    const openImgPrintDialog = frData?.openImgPrintDialog ?? false;
    const frPrintType = frData?.frPrintType;
    if (
        (
            frPrintType === frPrintTypes.PDFFILE ||
            frPrintType === frPrintTypes.PDFFILE_PRINT_ANDROID_OLD
        )
        && frData?.printer
    ) {
        try {
            const responseData = await fetcherAsync(isZReport ? configApiLinks.printZReportGetPdf : configApiLinks.printXReportGetPdf, 'POST', { frId: frData.id }, true);
            if (frPrintType === frPrintTypes.PDFFILE) {
                const file = new Blob([await responseData.blob()], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL, "PRINT");
            } else {
                const dynHtml = `print://escpos.org/escpos/bt/print?srcTp=uri&srcObj=png&numCopies=1&src='${encodeURIComponent(config.printUrl + "shifts/" + printFilename + ".pdf")}'`;
                window.location.href = dynHtml;
            }
        } catch (error) {
            console.log('printError', error.message);
        }
    } else if (
        frPrintType === frPrintTypes.PDFFILE_LINK ||
        frPrintType === frPrintTypes.IMGFILE_LINK ||
        frPrintType === frPrintTypes.PDFFILE_PRINT_ANDROID ||
        frPrintType === frPrintTypes.IMGFILE_PRINT_ANDROID
    ) {
        const fileExt = (frPrintType === frPrintTypes.PDFFILE_LINK || frPrintType === frPrintTypes.PDFFILE_LINK ? 'pdf' : 'png');
        const fileUrl = config.printUrl + "shifts/" + printFilename + "." + fileExt;
        if (frPrintType === frPrintTypes.PDFFILE_LINK || frPrintType === frPrintTypes.IMGFILE_LINK) {
            if (openImgPrintDialog && frPrintType === frPrintTypes.IMGFILE_LINK) {
                printImage(fileUrl);
            } else {
                window.open(fileUrl, "PRINT");
            }
        } else {
            const dynHtml = `print://escpos.org/escpos/bt/print?srcTp=uri&srcObj=png&numCopies=1&src='${encodeURIComponent(fileUrl)}'`;
            window.location.href = dynHtml;
        }
    }
};

export const printShowCashInOut = (paramsData) => {

};

export const printShowReceipt = async (receiptData, isCopy) => {

    const frPrintType = receiptData?.fiscalRegistrar?.frPrintType;
    const openImgPrintDialog = receiptData?.fiscalRegistrar?.openImgPrintDialog ?? false;
    if ((frPrintType === frPrintTypes.PDFFILE ||
        frPrintType === frPrintTypes.PDFFILE_PRINT_ANDROID_OLD) &&
        receiptData?.fiscalRegistrar?.printer) {
        try {
            const responseData = await fetcherAsync(configApiLinks.printReceiptGetPdf, 'POST', { receiptId: receiptData.id, isCopy }, true);
            if (frPrintType === frPrintTypes.PDFFILE) {
                const file = new Blob([await responseData.blob()], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL, "PRINT");
            } else {
                const dynHtml = `print://escpos.org/escpos/bt/print?srcTp=uri&srcObj=png&numCopies=1&src='${encodeURIComponent(config.printUrl + "receipts/" + receiptData.uuid + ".pdf")}'`;
                window.location.href = dynHtml;
            }
        } catch (error) {
            console.log('printError', error.message);
        }
    } else if (
        frPrintType === frPrintTypes.PDFFILE_LINK ||
        frPrintType === frPrintTypes.IMGFILE_LINK ||
        frPrintType === frPrintTypes.PDFFILE_PRINT_ANDROID ||
        frPrintType === frPrintTypes.IMGFILE_PRINT_ANDROID
    ) {
        await fetcherAsync(configApiLinks.printReceiptGetPdf, 'POST', { receiptId: receiptData.id, isCopy }, true);
        const fileExt = (frPrintType === frPrintTypes.PDFFILE_LINK || frPrintType === frPrintTypes.PDFFILE_LINK ? 'pdf' : 'png');
        const fileUrl = config.printUrl + "receipts/" + receiptData.uuid + "." + fileExt;
        if (frPrintType === frPrintTypes.PDFFILE_LINK || frPrintType === frPrintTypes.IMGFILE_LINK) {
            if (openImgPrintDialog && frPrintType === frPrintTypes.IMGFILE_LINK) {
                printImage(fileUrl);
            } else {
                window.open(fileUrl, "PRINT");
            }
        } else {
            const dynHtml = `print://escpos.org/escpos/bt/print?srcTp=uri&srcObj=png&numCopies=1&src='${encodeURIComponent(fileUrl)}'`;
            window.location.href = dynHtml;
        }

    }
};