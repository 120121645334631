module.exports = {
    PRINTER: 'PRINTER',
    PDFFILE: 'PDFFILE',
    PDFFILE_PRINT_ANDROID_OLD: 'PDFFILE_PRINT_ANDROID_OLD',
    PDFFILE_LINK: 'PDFFILE_LINK',
    PDFFILE_PRINT_ANDROID: 'PDFFILE_PRINT_ANDROID',
    IMGFILE_LINK: 'IMGFILE_LINK',
    IMGFILE_PRINT_ANDROID: 'IMGFILE_PRINT_ANDROID',
    PDFFRONTPRINT: 'PDFFRONTPRINT',
    DUMMY: 'DUMMY',
  };
  