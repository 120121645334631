export const configApiLinks = {
  getCheckData: 'receipts/shift/status',
  loginUser: 'login/',
  updateProductCount: 'receipts/product/setqty',
  makePayment: 'receipts/payment',
  addProduct: 'receipts/scannedCode',
  sendToApprove: 'receipts/process',
  getChecksHistory: 'receipts/history',
  removeProduct: 'receipts/product/delete',
  findProducts: 'products/findByParams',
  productsEditPrice: 'products/editPrice',
  findProductsBarcode: 'products/findByBarcode',
  findAddProductWeightCode: 'receipts/product/findAddByWeightCode',
  addProductById: 'receipts/product/add',
  addCashLink: 'receipts/frcashin',
  removeCashLink: 'receipts/frcashout',
  setDiscountManual: 'receipts/product/discount/setmanual',
  returnReceipt: 'receipts/return',
  cancelReceiptReturn: 'receipts/cancelreturn',
  clearReceiptProducts: 'receipts/clearproducts',
  getCashHistory: 'receipts/cashhistory',
  openShift: 'equipment/fiscregistrar/openshift',
  closeShift: 'equipment/fiscregistrar/zreport',
  xReport: 'equipment/fiscregistrar/xreport',
  getWeightFromScales: 'equipment/scales/getweight',
  printProductLabels: 'print/pricelabel/print',
  frGetData: 'equipment/fiscregistrar/frgetdata',
  frUpdateData: 'equipment/fiscregistrar/frupddata',
  receiptProductExciseAdd: 'receipts/product/esciseadd',
  receiptProductExciseDelete: 'receipts/product/escisedelete',
  receiptProductExciseSet: 'receipts/product/esciseset',
  display2ReceiptData: 'equipment/display2receiptdata',
  printReceiptGetPdf: 'print/receipt/getPdf',
  printXReportGetPdf: 'print/xreport/getPdf',
  printZReportGetPdf: 'print/zreport/getPdf',
  getReceiptWrapInfo: 'receipts/getreceiptwrapinfo'
};
