import { useState, useRef, useEffect } from 'react';
import { fetcherAsync } from '../../../helpers/fetcherAsync';
import { configApiLinks } from '../../../configApiLinks';
import { config } from '../../../config';
import { printShowReceipt } from '../../../helpers/printFrontEnd';

// import { useDispatch, useSelector, shallowEqual } from 'react-redux';

const emptyLiveData = { total: { paid: false, fiscalized: false } };

export const usePayProcessReceipt = (getShiftDataOnLoad = false) => {
  const [shiftData, setShiftData] = useState({});
  const shiftDataRef = useRef(shiftData);
  const [isLoading, setIsLoading] = useState(false);
  const [payProcessLiveData, setPayProcessLiveData] = useState(emptyLiveData);
  const payProcessLiveDataRef = useRef(emptyLiveData);

  useEffect(() => {
    if (getShiftDataOnLoad) {
      getShiftData();
    }
  }, []);

  const updatePayProcessLiveData = (updShiftData, updatePayment, updateFisc, receiptUpdData = null) => {

    let curPayProcessLiveData = structuredClone(payProcessLiveDataRef.current);
    // console.log('updatePayProcessLiveData-before0', payProcessLiveDataRef.current);
    // console.log('updatePayProcessLiveData-before', curPayProcessLiveData);
    if (updShiftData && (updatePayment || updateFisc)) {
      let paidTotal = null;
      let fiscalizedTotal = null;
      updShiftData.receipts.map(receipt => {
        if (!curPayProcessLiveData[receipt.id]) {
          curPayProcessLiveData[receipt.id] = { paid: false, fiscalized: false };
        }
        if (updatePayment) {
          if (paidTotal === null || receipt.paid) {
            paidTotal = receipt.paid;
          }
          if (!receiptUpdData?.receiptId || receiptUpdData?.receiptId === receipt.id) {
            curPayProcessLiveData[receipt.id].paid = receipt.paid;
          }
        }
        if (updateFisc) {
          if (fiscalizedTotal === null || !receipt.fiscalized) {
            fiscalizedTotal = receipt.fiscalized;
          }
          if (!receiptUpdData?.receiptId || receiptUpdData?.receiptId === receipt.id) {

            curPayProcessLiveData[receipt.id].fiscalized = receipt.fiscalized;
            if (receipt.fiscalized) {
              curPayProcessLiveData[receipt.id].paid = receipt.paid;
            }
          }
        }
      });
      if (updatePayment) {
        curPayProcessLiveData.total.paid = paidTotal ?? false;
      }
      if (updateFisc) {
        curPayProcessLiveData.total.fiscalized = fiscalizedTotal ?? false;
      }
    }

    if (receiptUpdData) {
      if (curPayProcessLiveData[receiptUpdData.receiptId]) {
        Object.keys(receiptUpdData.updData).map((objKey) => {
          curPayProcessLiveData[receiptUpdData.receiptId][objKey] = receiptUpdData.updData[objKey];
        });
      }
    }

    // console.log('updatePayProcessLiveData', { curPayProcessLiveData, params: { updShiftData, updatePayment, updateFisc, receiptUpdData } });
    // console.log('updatePayProcessLiveData', curPayProcessLiveData);
    setPayProcessLiveData(curPayProcessLiveData);
    payProcessLiveDataRef.current = curPayProcessLiveData;
  }

  const getShiftData = async (clearLiveCache = false) => {
    let curShiftData = {};
    setIsLoading(true);
    try {
      curShiftData = await fetcherAsync(configApiLinks.getCheckData, 'POST');
    } catch (error) {
      //shiftData = {};
    }
    setShiftData(curShiftData);
    shiftDataRef.current = curShiftData;
    setIsLoading(false);
    // console.log('updatePayProcessLiveData-getShiftData');
    if (clearLiveCache) {
      setPayProcessLiveData(emptyLiveData);
      payProcessLiveDataRef.current = emptyLiveData;
    }
    updatePayProcessLiveData(curShiftData, true, true);
  };

  const payReceipt = async (receiptData, paymentType, isTestReceipt = false, cashSum = 0, isTerminalCheckOnly = false) => {
    setIsLoading(true);
    let curShiftData = structuredClone(shiftDataRef.current);//{ ...shiftDataRef.current };
    // console.log('updatePayProcessLiveData-payReceipt-start');
    updatePayProcessLiveData(curShiftData, true, null, {
      receiptId: receiptData.id, updData: {
        updPayIsError: false,
        updPay: true,
        upd: true,
        errorMessagePayment: ''
      }
    });
    // setShiftData(curShiftData);
    // shiftDataRef.current = curShiftData;
    // console.log('curShiftData_updPay', curShiftData);
    let cash = 0,
      nonCash = 0,
      change = 0,
      total = receiptData.sumTotal;
    if (paymentType === 'CASH') {
      cash = receiptData.sumTotal;
    } else if (paymentType === 'CARD') {
      nonCash = receiptData.sumTotal;
    } else {
      cash = cashSum;
      nonCash = receiptData.sumTotal - cashSum;
    }
    try {
      curShiftData = await fetcherAsync(configApiLinks.makePayment, 'POST', {
        cash,
        nonCash,
        change,
        total,
        receiptId: receiptData.id,
        fiscalizeReceipt: false,
        testReceipt: isTestReceipt,
        isTerminalCheckOnly
      });
      // console.log('updatePayProcessLiveData-payReceipt-upd');
      updatePayProcessLiveData(curShiftData, true, null, { receiptId: receiptData.id, updData: { updPay: false } });
    } catch (error) {
      curShiftData = structuredClone(shiftDataRef.current);//{ ...shiftDataRef.current };
      // console.log('updatePayProcessLiveData-payReceipt-error');
      updatePayProcessLiveData(curShiftData, true, null, {
        receiptId: receiptData.id, updData: {
          updPayIsError: true,
          updPay: false,
          upd: false,
          errorMessagePayment: error.message,
        }
      });
    }
    setIsLoading(false);
    setShiftData(curShiftData);
    shiftDataRef.current = curShiftData;
  };

  const fiscReceipt = async (receiptData, isTestReceipt = false, weightDataReceipt = null) => {
    setIsLoading(true);
    let curShiftData = structuredClone(shiftDataRef.current);//{ ...shiftDataRef.current };
    // setReceiptUpdParams(curShiftData, receiptData, {
    //   updFiscIsError: false,
    //   updFisc: true,
    //   updPay: false
    // });
    // console.log('updatePayProcessLiveData-fiscReceipt-start');
    updatePayProcessLiveData(curShiftData, null, false, {
      receiptId: receiptData.id, updData: {
        updFiscIsError: false,
        updFisc: true,
        updPay: false,
        upd: true,
        errorMessage: ''
      }
    });
    // setShiftData(curShiftData);
    try {
      const curShiftDataF = await fetcherAsync(configApiLinks.sendToApprove, 'POST', {
        receiptId: receiptData.id,
        testReceipt: isTestReceipt,
        weightDataReceipt
      });
      if (receiptData?.receiptWrapId) {
        try {
          const curShiftDataWrap = await fetcherAsync(configApiLinks.getReceiptWrapInfo, 'POST', { receiptWrapId: receiptData.receiptWrapId });
          curShiftData.receipt = curShiftDataWrap.receipt;
          curShiftData.receipts = curShiftDataWrap.receipts;
          // console.log('receiptWrapData', { receiptWrapId: receiptData.receiptWrapId, curShiftData, curShiftDataWrap });
        } catch (error) { }
      }

      // console.log('updatePayProcessLiveData-fiscReceipt-upd');
      let recIsFiscalized = false;
      if (curShiftData.receipt.fiscalized) {
        recIsFiscalized = true;
      } else {
        curShiftData.receipts.map((cReceipt) => {
          if (cReceipt.id === receiptData.id && cReceipt.fiscalized) {
            recIsFiscalized = true;
          }
        });
      }
      updatePayProcessLiveData(curShiftData, true, true, {
        receiptId: receiptData.id, updData: {
          updFisc: false,
          updPay: false,
          upd: false,
          paid: curShiftData.receipt.paid,
          fiscalized: recIsFiscalized,
          errorMessage: ''
        }
      });
      // console.log('fiscReceiptSuccess');
      printShowReceipt(receiptData, false);
    } catch (error) {
      // console.log('fiscReceiptError', error.message);
      curShiftData = structuredClone(shiftDataRef.current);
      // console.log('updatePayProcessLiveData-fiscReceipt-error');
      updatePayProcessLiveData(null, null, null, {
        receiptId: receiptData.id, updData: {
          updFiscIsError: true,
          updFisc: false,
          errorMessage: error.message,
          updPay: false,
          upd: false
        }
      });
    }
    setIsLoading(false);
    setShiftData(curShiftData);
    shiftDataRef.current = curShiftData;
  };

  const showReceiptPDF = async (receiptData, isCopy = false) => {
    printShowReceipt(receiptData, isCopy);
  }

  return {
    shiftData: shiftDataRef.current,
    isLoading,
    getShiftData,
    payReceipt,
    fiscReceipt,
    setIsLoading,
    showReceiptPDF: printShowReceipt,
    payProcessLiveData,
    payProcessLiveRefData: payProcessLiveDataRef
  };
};
