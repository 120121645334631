import React, { useState, useEffect, Fragment } from 'react';
import { Col, Button, Modal, ListGroup, Form, Row } from 'react-bootstrap';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import classes from './footer_payment.module.css';
import {
  DISPLAY_MODAL,
  HIDE_MODAL,
  submitCheckPayment,
  setPaymentModalIsActive
} from '../../actions/checks';
import Loading from '../Loading';
import { formatMoney } from '../../helpers/dataFormatter';
import ModalPayment from '../modals/modal_payment_all';
import ModalPaymentKeyboard from '../modals/modal_payment_all_keyboard';
import { useWindowSize } from '../hooks/useWindowSize';

function Payment({ checkData }) {
  const { isNonMobile } = useWindowSize();
  const [showPayment, setShowPayment] = useState(false);
  const dispatch = useDispatch();
  const [nonCash, setNonCash] = useState(null);
  const [cash, setCash] = useState(null);
  const [change, setChange] = useState(null);
  const [total, setTotal] = useState(null);
  const [allowSubmitPayment, setAllowSubmitPayment] = useState(false);
  const hideAnyModal = useSelector((state) => state.actions.hideAnyModal);
  // const paymentModalIsActive = useSelector((state) => state.actions.paymentModalIsActive);
  const isShowLoader = useSelector(
    (state) => state.actions.isShowLoader,
    shallowEqual
  );
  const [modalPayment, setModalPayment] = useState({
    isActive: false,
    type: '',
  });

  useEffect(() => {
    dispatch(setPaymentModalIsActive(modalPayment.isActive));
  }, [modalPayment.isActive]);

  useEffect(() => {
    if (hideAnyModal == true) {
      setShowPayment(false);
    }
  }, [hideAnyModal]);

  useEffect(() => {
    if (showPayment === true) {
      // setNonCash(checkData.receipt.sumPayment.nonCash);
      // setCash(checkData.receipt.sumPayment.cash);
      // setChange(checkData.receipt.sumPayment.change);
      setTotal(checkData.receipt.sumTotal);
      setNonCash(0);
      setCash(0);
      setChange(0);
    }
  }, [showPayment, checkData]);

  // const handleClose = () => {
  //   setShowPayment(false);
  //   dispatch({ type: HIDE_MODAL });
  // };

  useEffect(() => {
    const sumPuttedMoney = parseFloat(cash) + parseFloat(nonCash);
    if (nonCash > total) {
      setChange(0);
      setAllowSubmitPayment(false);
    } else if (sumPuttedMoney > total) {
      setChange((total - sumPuttedMoney).toFixed(2));
      setAllowSubmitPayment(true);
    } else if (sumPuttedMoney < total) {
      setChange(0);
      setAllowSubmitPayment(false);
    } else if (sumPuttedMoney === total) {
      setAllowSubmitPayment(true);
      setChange(0);
    }
  }, [cash, nonCash]);

  const checkFixInputData = (inData) => {
    let resData;
    try {
      resData = parseFloat(inData);
    } catch (error) {
      resData = 0;
    }
    console.log('cash', resData ? resData : 0);
    return resData ? resData : 0;
  };

  const handleNonCash = (event) => {
    setNonCash(checkFixInputData(event.target.value));
  };
  const handleCash = (event) => {
    setCash(checkFixInputData(event.target.value));
  };

  const submitPayment = (event) => {
    event.preventDefault();
    dispatch(submitCheckPayment(cash, nonCash, change, total));
  };
  return (
    <Fragment>
      <Row className=' mb-1'>
        <Col xs={6}>
          <Button
            className={
              checkData && checkData.receipt && checkData.receipt.paid
                ? 'w-120 btn-outline-secondary ' + classes.btn_lg
                : 'w-120 btn-primary ' + classes.btn_lg
            }
            variant='primary'
            size='lg'
            style={{ width: isNonMobile ? '7.5rem' : '6rem' }}
            onClick={(e) => {
              e.target.blur();
              setModalPayment({ isActive: true, type: 'CARD' });
            }}
            // disabled={
            //   checkData && checkData.receipt && checkData.receipt.fiscalized
            // }
            disabled={checkData?.receipt?.fiscalized || checkData?.receipt?.exciseCodesError}
          >
            {/* {checkData && checkData.receipt && checkData.receipt.paid ? (
              <>&nbsp;Карта&nbsp;</>
            ) : (
              <>&nbsp;Карта&nbsp;</>
            )} */}
            Карта
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            className={
              checkData && checkData.receipt && checkData.receipt.paid
                ? 'w-120 btn-outline-secondary ' + classes.btn_lg
                : 'w-120 btn-warning ' + classes.btn_lg
            }
            variant='warning'
            size='lg'
            style={{ width: isNonMobile ? '7.5rem' : '6rem' }}
            onClick={(e) => {
              e.target.blur();
              setModalPayment({ isActive: true, type: 'CASH' });
            }}
            // disabled={
            //   checkData && checkData.receipt && checkData.receipt.fiscalized
            // }
            disabled={checkData?.receipt?.fiscalized || checkData?.receipt?.exciseCodesError}
          >
            {/* {checkData && checkData.receipt && checkData.receipt.paid
              ? 'Готівка'
              : 'Готівка'} */}
            Готівка
          </Button>
        </Col>
      </Row>
      {modalPayment.isActive && !(checkData && checkData.cashierWorkplace && checkData.cashierWorkplace.showNumericKeyborad === 1) && (
        <ModalPayment
          type={modalPayment.type}
          closeForm={() => {
            setModalPayment({
              isActive: false,
              type: '',
            });
          }}
        />
      )}
      {modalPayment.isActive && checkData && checkData.cashierWorkplace && checkData.cashierWorkplace.showNumericKeyborad === 1 && (
        <ModalPaymentKeyboard
          type={modalPayment.type}
          closeForm={() => {
            setModalPayment({
              isActive: false,
              type: '',
            });
          }}
        />
      )}
    </Fragment>
  );
}

export default Payment;
