import { React, useState, useEffect, useRef, Fragment } from 'react';
import {
  Col,
  Button,
  Modal,
  ListGroup,
  Form,
  Row,
  Table,
  Spinner,
  Container
} from 'react-bootstrap';
import { formatMoney } from '../../helpers/dataFormatter';
import classes from './modal_payment_all_keyboard.module.css';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {
  DISPLAY_MODAL,
  HIDE_MODAL,
  submitCheckPayment,
  getCheckData,
} from '../../actions/checks';
import { usePayProcessReceipt } from './hooks/usePayProcessReceipt';
import NumericKeyboard from '../ui/NumericKeyboard';
import { useWindowSize } from '../hooks/useWindowSize';
import { uSleep } from '../../helpers/utilsWCB';

const ModalPayment = (props) => {
  const { isNonMobile } = useWindowSize();
  const refCash = useRef('');
  const [total, setTotal] = useState(parseFloat(0.1));
  const [cash, setCash] = useState(null);
  const [cashFormatted, setCashFormatted] = useState(formatMoney(0));
  const [change, setChange] = useState(0);
  const [nonCash, setNonCash] = useState(0);
  const [allowSubmitPayment, setAllowSubmitPayment] = useState(true);
  const dispatch = useDispatch();
  const checkData = useSelector(
    (state) => state.actions.checkData,
    shallowEqual
  );
  const { shiftData, isLoading, getShiftData, payReceipt, fiscReceipt, setIsLoading, payProcessLiveData, payProcessLiveRefData } =
    usePayProcessReceipt();
  const shiftDataRef = useRef(shiftData);
  const isShowLoader = useSelector(
    (state) => state.actions.isShowLoader,
    shallowEqual
  );
  const isLoadingRef = useRef(false);

  // useEffect(() => {
  //   isLoadingRef.current = isLoading;
  // }, [isLoading]);

  const handleClose = () => {
    props.closeForm();
  };

  useEffect(() => { shiftDataRef.current = shiftData }, [shiftData]);

  const shiftDataUpd =
    shiftData && Object.keys(shiftData).length ? shiftData : checkData;

  const [isMixedPayment, setIsMixedPayment] = useState(false);
  const [mixedPaymentData, setMixedPaymentData] = useState({});
  // let shiftDataUpd;
  // if (shiftData && Object.keys(shiftData).length) {
  //   shiftDataUpd = shiftData;
  //   console.log('shiftDataUpd_shiftData', shiftData);
  // } else {
  //   shiftDataUpd = checkData;
  //   console.log('shiftDataUpd_checkData', checkData);

  // }
  const offlineTerminal = false;

  const distrMixedPaymentData = (inCash, inNonCash) => {
    console.log({ inCash, inNonCash });
    let curMixedPaymentData = {};
    let curReceipts = shiftDataUpd.receipts;
    if (curReceipts.length === 1) {
      curMixedPaymentData[curReceipts[0].id] = { cash: inCash, nonCash: inNonCash };
    } else {
      curReceipts.sort((a, b) => a.sumTotal - b.sumTotal);
      let cashStock = inCash;
      curReceipts.map((curReceipt) => {
        const curCashReceipt = Math.min(curReceipt.sumTotal, cashStock);
        cashStock -= curCashReceipt;
        curMixedPaymentData[curReceipt.id] = { cash: curCashReceipt, nonCash: curReceipt.sumTotal - curCashReceipt };
      });
    }
    console.log({ curMixedPaymentData });
    setMixedPaymentData(curMixedPaymentData);
  };

  useEffect(() => {
    getShiftData();
    if (props.type === 'CASH' && refCash.current) {
      setTotal(checkData && checkData.receipt ? checkData.receipt.sumTotal : 0);
      setCashFormatted(formatMoney(checkData && checkData.receipt ? checkData.receipt.sumTotal : 0));
      // refCash.current.focus();
      // refCash.current.select();
    }
  }, []);

  useEffect(() => {
    if (isMixedPayment) {
      setChange(0);
      if (cash === undefined || cash === null || cash >= total || cash < 1) {
        setNonCash(0);
        setAllowSubmitPayment(false);
        setMixedPaymentData({});
      } else {
        setNonCash(total - cash);
        setAllowSubmitPayment(true);
        distrMixedPaymentData(cash, total - cash);
      }
    } else {
      setNonCash(0);
      if (cash >= total || cash == null || cash === undefined) {
        setChange(cash === null ? 0 : (total - cash).toFixed(2));
        setAllowSubmitPayment(true);
      } else {
        setChange(0);
        setAllowSubmitPayment(false);
      }
    }
  }, [cash, isMixedPayment]);

  const checkFixInputData = (inData) => {
    let resData;
    try {
      resData = parseFloat(inData);
    } catch (error) {
      resData = 0;
    }
    console.log('cash', resData ? resData : 0);
    return resData ? resData : 0;
  };

  const handleCash = (event) => {
    setCashFormatted(formatMoney(checkFixInputData(event.target.value)));
    setCash(checkFixInputData(event.target.value));
  };

  const submitPaymentProcessReceipt = async (isTestReceipt = false) => {
    if (isLoadingRef.current || isLoading) {
      return;
    }
    isLoadingRef.current = true;
    setIsLoading(true);
    await uSleep(300);
    const shiftDataCurrent = shiftDataRef.current;
    if (shiftDataCurrent?.receipts?.length) {
      for (const receipt of shiftDataCurrent.receipts) {
        if (!receipt.paid || !receipt.fiscalized) {
          if (isMixedPayment) {
            await payReceipt(receipt, 'MIXED', isTestReceipt, mixedPaymentData[receipt.id].cash, false);
          } else {
            await payReceipt(receipt, props.type, isTestReceipt, 0, false);
          }
          isLoadingRef.current = true;
          await uSleep(800);
          if (!payProcessLiveRefData.current[receipt.id]?.paid && (payProcessLiveRefData.current[receipt.id]?.errorMessagePayment ?? '').toLowerCase().includes('general error')) {
            const payCounts = 3;
            let itPayCount = 0;
            while (!payProcessLiveRefData.current[receipt.id]?.paid && itPayCount < payCounts) {
              itPayCount++;
              if (isMixedPayment) {
                await payReceipt(receipt, 'MIXED', isTestReceipt, mixedPaymentData[receipt.id].cash, true);
              } else {
                await payReceipt(receipt, props.type, isTestReceipt, 0, true);
              }
              await uSleep(800);
            }
          }
          console.log('test_payProcess_submitPaymentProcessReceipt', { payProcessLiveRefData: payProcessLiveRefData.current });
          if (payProcessLiveRefData.current[receipt.id]?.paid) {
            await fiscReceipt(receipt, isTestReceipt);
          }
        }
      }
      dispatch(getCheckData());
    }
    isLoadingRef.current = false;
  };

  console.log('shiftDataUpd', shiftDataUpd);

  return (
    <div style={{ width: '15vh' }} className={classes.bodyFull} >
      <Modal
        show={true}
        onHide={props.closeForm}
        // size={props.type === 'CASH' ? 'xl' : 'md'}
        dialogClassName={isNonMobile ? classes.modalWrapKb : classes.modalWrapKbMobile}
        centered
        scrollable={true}
      // style={{
      //   width: 'fitContent',
      //   maxWidth: '43.3vh !important'
      // }}
      >
        <Modal.Header className={(props.type === 'CASH' || isMixedPayment) ? classes.body : classes.bodyCard} >
          <Modal.Title>
            <Row>
              <Col>
                Оплата -{' '}
                {formatMoney(
                  shiftDataUpd && shiftDataUpd.receipt
                    ? shiftDataUpd.receipt.sumTotal
                    : ''
                )}{' '}

                грн.
              </Col>
              {props.type === 'CASH' &&
                <Col style={{ minWidth: 'fit-content' }}>
                  <Form>
                    <Form.Check
                      // inline
                      label='Змішана оплата'
                      name='group1'
                      type='switch'
                      id={`inline-checkbox-1`}
                      onChange={(e) => {
                        setIsMixedPayment(e.target.checked);
                        // if (!manualEdit) {
                        //   setTimeout(() => {
                        //     focusSelectCount();
                        //   }, 50);
                        // }
                        // startStopScalesUpdate(manualEdit);
                        // setManualEdit(!manualEdit);
                      }}
                    // checked={manualEdit}
                    />
                  </Form>
                </Col>}
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={props.type === 'CASH' || isMixedPayment ? classes.body : classes.bodyCard}>
          {/* <p>{JSON.stringify(payProcessLiveRefData.current, null, 2)}</p> */}
          <Container>
            <Row>
              <Col xs={((props.type === 'CASH' || isMixedPayment) && isNonMobile) ? 7 : 12}>
                {(props.type === 'CASH' || isMixedPayment) && (
                  <Fragment>
                    <ListGroup>
                      <ListGroup.Item>
                        <Form>
                          <Form.Group as={Row} controlId='cash'>
                            <Form.Label className='fw-bold' column xs='5'>
                              <i className="bi bi-cash-coin" /> Готівка
                            </Form.Label>
                            <Col xs='7'>
                              <Form.Control
                                ref={refCash}
                                onChange={handleCash}
                                //value={cash}
                                type='number'
                                min='0'
                                inputMode='text'
                                autoComplete='off'
                                // placeholder={formatMoney(total)}
                                // onFocus={(e) => {
                                //   e.target.select();
                                // }}
                                // onKeyDown={(e) => {
                                //   if (e.key === 'Enter') {
                                //     e.preventDefault();
                                //     if (shiftDataUpd &&
                                //       shiftDataUpd.receipt &&
                                //       !shiftDataUpd.receipt.fiscalized &&
                                //       shiftDataUpd.receipt.sumTotal > 0) {
                                //       submitPaymentProcessReceipt();
                                //       e.target.blur();
                                //       e.target.parentElement.focus();
                                //       e.target.parentElement.blur();
                                //     }
                                //   }
                                // }}
                                value={cashFormatted}
                                readOnly
                              />
                            </Col>
                          </Form.Group>
                        </Form>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {(!isMixedPayment) && <Row>
                          <Col className='fw-bold' xs='5'>
                            <i className="bi bi-cash-coin" /> Решта
                          </Col>
                          <Col xs='7'>{formatMoney(0 - change)}</Col>
                        </Row>}
                        {(isMixedPayment) && <Row>
                          <Col className='fw-bold' xs='5'>
                            <i className="bi bi-credit-card" /> Карта
                          </Col>
                          <Col xs='7'>{formatMoney(nonCash)}</Col>
                        </Row>}
                      </ListGroup.Item>
                    </ListGroup>
                    <br />
                  </Fragment>
                )}
                <Table size='sm'>
                  <thead>
                    <tr key={'pay-list-head'} className={classes.head}>
                      <th className='col-6'>Фіскальний реєстратор</th>
                      <th className='text-end text-nowrap'>Сума,₴</th>
                      <th className='text-center'>Спл.</th>
                      <th className='text-center'>Проб.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      //!isShowLoader &&
                      shiftDataUpd &&
                      shiftDataUpd.receipts &&
                      shiftDataUpd.receipts.length > 0 &&
                      shiftDataUpd.receipts.map((receipt) => {
                        if (receipt.sumTotal > 0) {
                          const curReceiptLiveData = payProcessLiveRefData.current[receipt.id];
                          const rStatusPaid = ((curReceiptLiveData?.paid ?? false) || receipt.paid);
                          const rStatusFiscalized = ((curReceiptLiveData?.fiscalized ?? false) || receipt.fiscalized);
                          const rStatusIsLoadingPay = (curReceiptLiveData?.updPay && !rStatusPaid) ?? false;
                          const rStatusIsLoadingFisc = (curReceiptLiveData?.updFisc && !rStatusFiscalized) ?? false;
                          const rStatusIsLoading = ((curReceiptLiveData?.updPay || curReceiptLiveData?.updFisc || curReceiptLiveData?.upd) && !rStatusFiscalized) ?? false;
                          const rStatusErrorPay = ((curReceiptLiveData?.updPayIsError) && !rStatusIsLoadingPay) ?? false;
                          const rStatusErrorFisc = ((curReceiptLiveData?.updPayIsError || curReceiptLiveData?.updFiscIsError) && !rStatusIsLoading) ?? false;
                          const rStatusError = ((curReceiptLiveData?.updPayIsError || curReceiptLiveData?.updFiscIsError) && !rStatusIsLoading) ?? false;
                          const rStatusNone = (!rStatusIsLoading && !rStatusFiscalized && !rStatusError) ?? false;
                          const rStatusPayNone = (!rStatusIsLoadingPay && !rStatusPaid && !rStatusErrorPay) ?? false;
                          const rStatusFiscNone = (!rStatusIsLoadingFisc && !rStatusFiscalized && !rStatusErrorFisc) ?? false;
                          console.log('paymentModalLive', { rStatusPaid, rStatusFiscalized, rStatusIsLoading, rStatusIsLoadingFisc, rStatusIsLoadingPay, rStatusError, rStatusErrorPay, rStatusErrorFisc, rStatusNone, rStatusPayNone, rStatusFiscNone, curReceiptLiveData });
                          return (
                            <Fragment key={receipt.id + 'pay-list-fr'}>
                              <tr className={classes.row}>
                                <td colSpan={1}>
                                  <p className='mb-1'>
                                    {`${receipt.fiscalRegistrar.name}`}
                                  </p>
                                  {(isMixedPayment) &&
                                    <p className='mb-1'>
                                      <i className="bi bi-cash-coin" style={{ paddingRight: '3px' }} />{formatMoney(mixedPaymentData[receipt.id] ? mixedPaymentData[receipt.id].cash : 0)} &nbsp;<i className="bi bi-credit-card" style={{ paddingRight: '3px' }} />{formatMoney(mixedPaymentData[receipt.id] ? mixedPaymentData[receipt.id].nonCash : 0)}
                                    </p>}
                                </td>
                                <td colSpan={1}>
                                  <p className='mb-1 text-end text-nowrap'>
                                    {formatMoney(receipt.sumTotal)}
                                  </p>
                                </td>
                                <td colSpan={1}>
                                  <p className='mb-1 text-center'>
                                    {rStatusPayNone && (<i className='bi bi-circle' style={{ fontSize: 32, color: 'grey' }} ></i>)}
                                    {rStatusIsLoadingPay && (<Spinner animation='border' variant='warning' />)}
                                    {rStatusErrorPay && (<i className='bi bi-exclamation-circle-fill' style={{ fontSize: 32, color: 'red' }}></i>)}
                                    {rStatusPaid && (<i className='bi bi-check-circle' style={{ fontSize: 32, color: 'green' }}></i>)}
                                  </p>
                                </td>
                                <td colSpan={1}>
                                  <p className='mb-1 text-center'>
                                    {rStatusFiscNone && (<i className='bi bi-circle' style={{ fontSize: 32, color: 'grey' }} ></i>)}
                                    {rStatusIsLoadingFisc && (<Spinner animation='border' variant='warning' />)}
                                    {rStatusErrorFisc && (<i className='bi bi-exclamation-circle-fill' style={{ fontSize: 32, color: 'red' }}></i>)}
                                    {rStatusFiscalized && (<i className='bi bi-check-circle' style={{ fontSize: 32, color: 'green' }}></i>)}
                                  </p>
                                </td>
                              </tr>
                              {payProcessLiveData[receipt.id]?.errorMessagePayment && (
                                <tr className={classes.row}>
                                  <td colSpan={4}>
                                    <p className='mb-1 text-danger'>{payProcessLiveData[receipt.id]?.errorMessagePayment}</p>
                                  </td>
                                </tr>
                              )}
                              {payProcessLiveData[receipt.id]?.errorMessage && (
                                <tr className={classes.row}>
                                  <td colSpan={4}>
                                    <p className='mb-1 text-danger'>{payProcessLiveData[receipt.id]?.errorMessage}</p>
                                  </td>
                                </tr>
                              )}
                            </Fragment>
                          );
                        }
                      })
                    }
                  </tbody>
                </Table>
                {(props.type === 'CARD' && !isMixedPayment) &&
                  offlineTerminal &&
                  !shiftDataUpd.receipt.paid && (
                    <ListGroup>
                      <ListGroup.Item>
                        <Form>
                          {/* <Form.Group as={Row} controlId='nonCash'>
                
              </Form.Group> */}
                          Оплата на терміналі пройшла успішно?
                        </Form>
                      </ListGroup.Item>
                    </ListGroup>
                  )}
              </Col>
              {(props.type === 'CASH' || isMixedPayment) &&
                <Col className="justify-content-md-center" style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={classes.numericKeyboard}>
                    <NumericKeyboard setValue={(keyboardValue) => { handleCash({ target: { value: keyboardValue } }) }} handleSubmit={null} floatPoint={true} />
                  </div>
                </Col>
              }
            </Row>
          </Container>
        </Modal.Body>

        {props.type === 'CARD' && offlineTerminal && !shiftDataUpd.receipt.paid && (
          <Modal.Footer>
            <Button size='lg' variant='outline-secondary' onClick={handleClose}>
              Ні
            </Button>
            <Button
              className='w-50'
              size='lg'
              onClick={() => { submitPaymentProcessReceipt(false) }}
              variant='success'
              disabled={isLoadingRef.current || isLoading}
            >
              Так
            </Button>
          </Modal.Footer>
        )}
        {/* {props.type === 'CARD' &&
        (!offlineTerminal || shiftDataUpd.receipt.paid) && (
          <Modal.Footer>
            <Button size='lg' variant='outline-secondary' onClick={handleClose}>
              Закрити
            </Button>
            <Button
              className='w-50'
              size='lg'
              onClick={submitPaymentProcessReceipt}
              disabled={
                shiftDataUpd &&
                shiftDataUpd.receipt &&
                shiftDataUpd.receipt.fiscalized
                  ? true
                  : false
              }
              variant='success'
            >
              Оплата терм.
            </Button>
          </Modal.Footer>
        )} */}

        {(props.type === 'CARD' && !isMixedPayment) &&
          (!offlineTerminal || shiftDataUpd.receipt.paid) && (
            <Modal.Footer>
              <Button size='lg' variant='outline-secondary' onClick={handleClose}>
                Закрити
              </Button>
              <Button
                className='w-50'
                size='lg'
                onClick={() => { submitPaymentProcessReceipt(false) }}
                disabled={payProcessLiveRefData.current?.total?.fiscalized || isLoadingRef.current || isLoading ||
                  (shiftDataUpd && shiftDataUpd.receipt &&
                    shiftDataUpd.receipt.sumTotal > 0 &&
                    !shiftDataUpd.receipt.fiscalized
                    ? false
                    : true)
                }
                variant='success'
              >
                {isLoading === undefined || (!isLoading && 'Оплата терм.')}
                {isLoading !== undefined && isLoading && (
                  <Fragment>
                    <span
                      className='spinner-grow spinner-grow-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                    {' '}Оплата терм...
                  </Fragment>
                )}
              </Button>
              {/* <button className='btn btn-primary' type='button' disabled>
              <span
                className='spinner-grow spinner-grow-sm'
                role='status'
                aria-hidden='true'
              ></span>
              Оплата терм....
            </button> */}
            </Modal.Footer>
          )}
        {(props.type === 'CASH' || isMixedPayment) && (
          <Modal.Footer>
            <Button size='lg' variant='outline-secondary' onClick={handleClose}>
              Закрити
            </Button>
              <Button
                className='w-50'
                size='lg'
                onClick={async () => { submitPaymentProcessReceipt(false); }}
                //disabled={!allowSubmitPayment}
                disabled={payProcessLiveRefData.current?.total?.fiscalized || isLoadingRef.current || isLoading ||
                  (allowSubmitPayment && shiftDataUpd &&
                    shiftDataUpd.receipt &&
                    shiftDataUpd.receipt.sumTotal > 0 &&
                    !shiftDataUpd.receipt.fiscalized
                    // &&
                    // (shiftDataUpd.receipt.sumTotal <= cash || !cash)
                    ? false
                    : true)
                }
                variant='success'
              >
                {(isLoading === undefined || !isLoading) && 'Оплата'}
                {isLoading !== undefined && isLoading && (
                  <Fragment>
                    <span
                      className='spinner-grow spinner-grow-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                    {' '}Оплата...
                  </Fragment>
                )}
              </Button>
            {(shiftDataUpd?.cashierWorkplace?.enableTestReceipt > 0 && !isMixedPayment && !payProcessLiveData?.total?.fiscalized) &&
              <Button
                className='w-25'
                size='lg'
                onClick={() => { submitPaymentProcessReceipt(true) }}
                //disabled={!allowSubmitPayment}
                disabled={payProcessLiveData?.total?.fiscalized || isLoadingRef.current || isLoading ||
                  (allowSubmitPayment && shiftDataUpd &&
                    shiftDataUpd.receipt &&
                    shiftDataUpd.receipt.sumTotal > 0 &&
                    !shiftDataUpd.receipt.fiscalized &&
                    (shiftDataUpd.receipt.sumTotal <= cash || !cash)
                    ? false
                    : true)
                }
                variant='warning'
              >
                {(isLoading === undefined || !isLoading) && 'Тест'}
                {isLoading !== undefined && isLoading && (
                  <Fragment>
                    <span
                      className='spinner-grow spinner-grow-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                    {' '}Тест...
                  </Fragment>
                )}
              </Button>
            }
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default ModalPayment;
